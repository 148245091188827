body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #01469a; /* Old browsers */
	background: -moz-linear-gradient(45deg, #01469a 0%, #017dea 51%, #44caff 100%); /* FF3.6-15 */
	background: -webkit-linear-gradient(45deg, #01469a 0%,#017dea 51%,#44caff 100%); /* Chrome10-25,Safari5.1-6 */
	background: linear-gradient(45deg, #01469a 0%,#017dea 51%,#44caff 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  min-height: 100vh;
  height: auto
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
